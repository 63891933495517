import './App.scss';
import {ChakraProvider} from "@chakra-ui/react"
import {Stepper} from './comnponents/Stepper';
import ReactGA from 'react-ga';
import {IntlProvider} from "react-intl";
import German from './lang/de.json';
import French from './lang/fr.json';
import Dutch from './lang/nl.json';
import Danish from './lang/dk.json';
import Norwegian from './lang/no.json';
import Swedish from './lang/se.json';
import English from './lang/en.json';
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/nl';
import 'moment/locale/sv';
import 'moment/locale/en-gb';

const currentUrl = window.location.toString();

let messages = German;
let locale = 'de';
moment.locale('de');

switch (true) {
    case (currentUrl.search('/fr') !== -1):
        messages = French;
        locale = 'fr';
        moment.locale('fr');
        break;

    case (currentUrl.search('/dk') !== -1):
        messages = Danish;
        locale = 'dk';
        moment.locale('da');
        break;

    case (currentUrl.search('/no') !== -1):
        messages = Norwegian;
        locale = 'no';
        moment.locale('nb');
        break;

    case (currentUrl.search('/se') !== -1):
        messages = Swedish;
        locale = 'se';
        moment.locale('sv');
        break;

    case (currentUrl.search('/nl') !== -1):
        messages = Dutch;
        locale = 'nl';
        moment.locale('nl');
        break;

    case (currentUrl.search('/uk') !== -1):
        messages = English;
        locale = 'en';
        moment.locale('en-gb');
        break;

    case (currentUrl.search('/benl') !== -1):
        messages = Dutch;
        messages.outgoingUrl = 'https://plus-abnehmen.com/benl/bestel.html';
        locale = 'nl';
        moment.locale('nl');
        break;
    case (currentUrl.search('/befr') !== -1):
        messages = French;
        messages.outgoingUrl = 'https://plus-abnehmen.com/befr/commander.html';
        locale = 'nl';
        moment.locale('fr');
        break;

    case (currentUrl.search('/lude') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://plus-abnehmen.com/lude/jetzt-kaufen.html';
        locale = 'de';
        break;
    case (currentUrl.search('/lufr') !== -1):
        messages = French;
        messages.outgoingUrl = 'https://plus-abnehmen.com/lufr/commander.html';
        locale = 'fr';
        moment.locale('fr');
        break;

    case (currentUrl.search('/at') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://plus-abnehmen.com/at/jetzt-kaufen.html';
        locale = 'de';
        break;

    case (currentUrl.search('/ch') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://plus-abnehmen-kapseln.com/ch/jetzt-kaufen.html';
        locale = 'de';
        break;

    default:
        locale = 'de';
}

document.title = 'PLUS - ' + messages.title;

function App() {
    ReactGA.initialize('UA-183263461-14');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className="App">
            <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                <ChakraProvider>
                    <Stepper/>
                </ChakraProvider>
            </IntlProvider>
        </div>
    );
}

export default App;
