import React from 'react';
import {Box, Grid, GridItem, Container, Icon, Text} from '@chakra-ui/react';
import {PageHeader} from './PageHeader';
import {CheckCircleIcon, ExternalLinkIcon} from '@chakra-ui/icons'
import {FormattedMessage, useIntl} from "react-intl";

export const PageLayout = ({children}) => {
    const intl = useIntl()
    let country = intl.locale;

    const currentUrl = window.location.toString();

    if (currentUrl.search('/lude') !== -1) {
        country = 'lude';
    }

    return (
        <Box>
            <PageHeader/>
            <Container className="main-contianer" maxW="container.lg">
                <Grid mb={20} templateColumns="repeat(2, 1fr)">
                    <GridItem rowSpan={2} colSpan={1} pt={20}>
                        <Box className="text-icon-conatiner">
                            <Box className="icon-text" mb="6">
                                <Box className="icon">
                                    <CheckCircleIcon w={8} h={8}/>
                                </Box>
                                <Box>
                                    <Text>
                                        <FormattedMessage id="start.fact.1"/>
                                    </Text>
                                </Box>
                            </Box>
                            <Box className="icon-text" mb="6">
                                <Box className="icon">
                                    <CheckCircleIcon w={8} h={8}/>
                                </Box>
                                <Box>
                                    <Text>
                                        <FormattedMessage id="start.fact.2"/>
                                    </Text>
                                </Box>
                            </Box>
                            <Box className="icon-text" mb="6">
                                <Box className="icon">
                                    <CheckCircleIcon w={8} h={8}/>
                                </Box>
                                <Box>
                                    <Text>
                                        <FormattedMessage id="start.fact.3"/>
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem className="form-wrapper" rowSpan={2}>
                        <Box className="form-container" mt={-40} boxShadow="xl" mx="10" p="8" rounded="md">
                            {children}
                        </Box>
                    </GridItem>
                </Grid>
            </Container>

            <Box id={"footer"} mt={10} borderTop="2px" fontSize="xs" borderColor="gray.200" textAlign={"center"}>
                <Container className="main-contianer" maxW="container.lg">
                    <Grid templateColumns="repeat(2, 1fr)">
                       <GridItem pt={5} pb={5} textAlign={"left"}>
                           Copyright © 2021 PLUS - All Rights Reserved.
                       </GridItem>
                        {country === 'de' &&
                        <GridItem pt={5} pb={5} textAlign={"right"}>
                            <a target={"_blank"} rel={"noreferrer"} href="https://blitzadsmedia.com/signup-1/index.html">
                                <Icon as={ExternalLinkIcon} /> Affiliates
                            </a>
                        </GridItem>
                        }
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};
